(function () {
  const fullUrl = window.location.href;
  const utmParameters = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
  const expireDays = 180;

  const urlContainsUtm = utmParameters.some((param) => fullUrl.includes(param));

  const cookieExists = document.cookie.includes('_sthr_utm_url');

  if (urlContainsUtm && !cookieExists) {
    const date = new Date();
    const suffix = `&utm_at=${date.toISOString()}`;
    date.setDate(date.getDate() + expireDays);
    document.cookie = `_sthr_utm_url=${fullUrl}${suffix}; expires=${date.toUTCString()}; Secure`;
  }
}());
