/* ----------------------------------------------------------------------------
Subscribe to Beehiiv via magic links
---------------------------------------------------------------------------- */

export default class BeehiivSubscribe extends HTMLElement {
  submittable() {
    this.formElement.addEventListener('submit', (e) => {
      e.preventDefault();
      const email = this.inputElement.value;
      window.location.href = `${this.magicUrl}?email=${email}`;
    });
  }

  connectedCallback() {
    this.formElement = this.querySelector('form');
    this.inputElement = this.querySelector('input');
    this.magicUrl = this.formElement.getAttribute('action');
    this.submittable();
  }
}

window.customElements.define('beehiiv-subscribe', BeehiivSubscribe);
